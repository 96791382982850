<template>
  <div>
    <div id="main" class="site-main">

  <div id="main-content" class="main-content">

  <div id="page-title" class="page-title-block page-title-style-1 " style=""><div class="container"><div class="page-title-title"><h1 style=""><div v-html="`${ntitle}`"></div> </h1></div></div></div>




  <div class="block-content">
  	<div class="container">
  		<div class="panel row">

  			<div class="panel-center col-xs-12">
  				<article :id="`post-${this.$route.params.id}`" :class="`post-${this.$route.params.id} post type-post status-publish format-standard has-post-thumbnail hentry category-events`">


  					<div class="entry-content post-content">
  												<div v-html="content"></div>
  					</div><!-- .entry-content -->


  											<div class="post-author-block rounded-corners clearfix">
  				<div class="post-author-avatar"><img alt='ARST' src='/wp-content/uploads/2015/12/ARST_avatar_1450015994-72x72.png' class='avatar avatar-72 photo' height='72' width='72' /></div>
  				<div class="post-author-info">
  					<div class="name styled-subtitle">ARST</div>

  					<div class="date-info">
  						<span class="date">{{postdate}} </span>
  																			<categorylinks :categories="categories" />
  												<div class="description"></div>

  					</div>
  				</div>
  			</div>

  											<div class="clearfix scalia_socials_sharing">
  														<div class="block-tags"><div class="block-date">{{postdate}}</div></div>						</div>
  						<div class="block-divider"></div>
  						<div class="block-navigation">
  														<div v-if="previd > 0" class="block-navigation-prev"> <router-link :to="`/news/${previd}`"> Предыдущий пост  </router-link></div>
  							<div v-if="nextid > 0" class="block-navigation-next">
                       <router-link :to="`/news/${nextid}`"> Следующий пост  </router-link>
                       </div>
  						</div>




  				</article><!-- #post-## -->

  			</div>


  		</div>


  	</div>
  </div>



  </div>

  </div>
</div>
</template>

<script>
import "datejs"
import {getwppost, getwpprevpost, getwpnextpost, rollup} from "@/lib/utils.js"
import categorylinks from "@/components/categorylinks.vue"

export default {
name : "shownews",
components : {categorylinks},
data() {


return {
cdata : {},
content : '',
postdate : '',
previd : 0,
nextid : 0,
ntitle : '',
categoryname : '',
categoryid : 0,
categories : []

}

},
methods : {
async getpostcontent() {
let data = {}
  if(this.$route.params.contentdata)
  {

    data = this.$route.params.contentdata



  }else {
    data = await getwppost(this.$route.params.id)

    }
    this.ntitle = data.title.rendered
    this.content = data.content.rendered
    this.postdate = data.postdateformated
    this.categoryname = data.categoryname
    this.categoryid = data.categories[0]
    this.categories = data.categoriesarray

//console.log("shownews.vue")
//console.log(this.categories)

window.scrollTo(0, 0)

this.previd=0
this.previd = await getwpprevpost(data.date,data.categories[0])

this.nextid=0
this.nextid = await getwpnextpost(data.date,data.categories[0])


//console.log(`previd ${this.previd}`)

  }



},
mounted() {
//console.log(this.$route.params)

this.getpostcontent()
rollup()


},
watch : {

$route() {
this.getpostcontent()
rollup()
}

}


}
</script>
