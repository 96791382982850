<template>
  <span class="categories">
    <span v-for="(cat,index) in categories">
    <router-link :to="`/rubricator/${cat.id}`" :title="`Посмотреть все посты в ${cat.name}`"> {{cat.name}} </router-link>
      <span v-if="index < categories.length-1">,</span>
  </span>
  </span>
</template>

<script>
export default {
name : "categorylinks",
props : ['categories'],
mounted() {
console.log('categorylinks.vue')
console.log(this.categories)

}


}
</script>
